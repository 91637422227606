import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BusinessAreaModel} from '../../../../_models/business-area.model';
import {map} from 'rxjs/operators';
import {APIResp} from '../../../../_models/api-resp.model';
import {BusinessSectorService} from '../../../../services/business-sector.service';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {GlobalStoreService} from '../../../../services/globalStore.service';

@Component({
    selector: 'app-company-info',
    templateUrl: './company-info.component.html',
    styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit, OnChanges {

    @Input() formGroup: FormGroup;
    @Input() bannerTitle: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() permissions;
    @Input() creation;
    @Input() showError;
    lang = 'pt';

    businessAreas: BusinessAreaModel[] = [];
    businessAreas$: BehaviorSubject<BusinessAreaModel[]> = new BehaviorSubject<BusinessAreaModel[]>([]);

    showCompanyNameError = false;
    showCompanyLegalNameError = false;
    showCompanyVATError = false;
    showCompanyBusinessSectorError = false;
    showCompanyEmailError = false;
    showCompanyAddressError = false;

    constructor(
        private businessSectServ: BusinessSectorService,
        private translate: TranslateService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formGroup && changes.formGroup.currentValue) {
            setTimeout(() => {
                this.setBusinessSectors();
            }, 500);
        }
        if (changes.showError?.currentValue) {
            this.changesControl();
        }
    }

    ngOnInit(): void {}

    setBusinessSectors() {
        this.lang = this.translate.currentLang;
        this.businessSectServ
            .list({}).pipe(
            map((resp: APIResp<BusinessAreaModel>) => {
                const ret = [];
                resp.data.forEach(r => {
                    if (r.translatedNames && (r.translatedNames.pt || r.translatedNames.en || r.translatedNames.es)) {
                        ret.push({
                            uuid: r.uuid,
                            name: r.translatedNames[this.lang]
                        });
                    } else {
                        ret.push({
                            uuid: r.uuid,
                            name: r.name
                        });
                    }
                });
                return ret;
            }))
            .subscribe(r => {
                r.unshift({
                    uuid: '',
                    name: this.translate.instant('Select business area'),
                });
                this.businessAreas = r;
                this.businessAreas$.next(r);
            }
        );
    }

    setBSector(value) {
        const bs = this.businessAreas.find(el => el.uuid === value.uuid)?.name;
        return bs ? bs : ' ';
    }

    catchOptionChange(event) {
        this.formGroup.controls.businessSector.setValue(event);
        this.changesControl();
    }

    changesControl() {
        if (this.showError) {
            const controls = this.formGroup.controls;
            const nameStatus = controls.name.status;
            const legalNameStatus = controls.legalName.status;
            const vatStatus = controls.vat.status;
            const businessSectorStatus = controls.businessSector.status;
            const emailStatus = controls.email.status;
            const addressStatus = controls.address.status;
            this.showCompanyNameError = nameStatus === 'INVALID';
            this.showCompanyLegalNameError = legalNameStatus === 'INVALID';
            this.showCompanyVATError = vatStatus === 'INVALID';
            this.showCompanyBusinessSectorError = businessSectorStatus === 'INVALID';
            this.showCompanyEmailError = emailStatus === 'INVALID';
            this.showCompanyAddressError = addressStatus === 'INVALID';
        }
    }
}
